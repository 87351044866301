
.connectStyle{
    transition: 1s all;
}

.connectStyle:hover {
    box-shadow: 1px 1px 7px var(--rating-color);
}

.successAnimate{
    width: 45px;
    height: 45px;
}


@media only screen and (min-width: 601px) {
    .container {
        width: 90% !important;
        margin-inline: auto;
        margin-top: 130px;
        
    }

    .header {
        border-bottom: 4px solid var(--rating-color) ;
        border-left: 4px solid var(--rating-color) ;
        border-right: 4px solid var(--rating-color) ;
        width: 30%;
        padding-bottom: 8px;
        margin-inline: auto;
    }
    

}


@media only screen and (max-width: 601px) {
    .container {
        width: 95% !important;
        margin-inline: auto;
        margin-top: 150px;

    }

    .header {
        border-bottom: 4px solid var(--rating-color) ;
        border-left: 4px solid var(--rating-color) ;
        border-right: 4px solid var(--rating-color) ;
        width: 98%;
        padding-bottom: 8px;
        margin-inline: auto;
    }
    
}


.buttonStyle{
    background-color: var(--main-color) !important;
    opacity: 0.9;
    transition: all 0.5s !important;

}

.buttonStyle:hover {
    transform: scale(0.95);

}