

@media only screen and (min-width: 601px) {
    .coverPic {
        width: 100%;
        height: 100vh;

        
    }



    .container {
        width: 90% ;
        margin-inline: auto ;
    }
    

    
    .header {
        border-bottom: 4px solid var(--rating-color) ;
        border-left: 4px solid var(--rating-color) ;
        border-right: 4px solid var(--rating-color) ;
        width: 30%;
        padding-bottom: 8px;
        margin-inline: auto;
    }

    .spaceBetween{
        padding-inline: 0px ;
      
    }

    .profileStyle{
        margin-top: 80px;
    }

    .picBorder {

        padding: 10px 0 0 10px;
        border-top: 5px solid var(--rating-color);
        border-left: 5px solid var(--rating-color);
    }

}

@media only screen and (min-width: 992px) {


    .spaceBetween{
        padding-inline: 60px ;
    }

    .titleStyle {
        font-size: 200px !;
        margin-top: 200px;
    }
    
    .titleStyle {
        font-size: 100px;
        margin-top: 90px;
    }

    .profileStyle{
        margin-top: 80px;
    }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
    .sizeProfile{
        font-size: 20px ;
    }
  }

  @media only screen and (min-width: 601px) and (max-width: 992px) {
    .titleStyle {
        font-size: 100px;
        margin-top: 200px;
    }

    .picBorder {
        display: none;
        padding: 10px 0 0 10px;
        border-top: 5px solid var(--rating-color);
        border-left: 5px solid var(--rating-color);
    }
  }

  @media only screen and (min-width: 1201px) and (max-width: 1710px) {
    .sizeProfile{
        font-size: 25px ;
    }
  }

  @media only screen and (min-width: 1400px) and (max-width: 1710px) {
    .sizeProfile{
        font-size: 30px ;
    }
  }

@media only screen and (max-width: 600px) {
    .coverPic {
        width: 100%;
        height: 100vh;
        
        
    }

    .titleStyle {
        font-size: 60px;
        margin-top: 90px;
    }

    .container {
        width: 90% ;
        margin-inline: auto ;
    }

    .header {
        border-bottom: 4px solid var(--rating-color) ;
        border-left: 4px solid var(--rating-color) ;
        border-right: 4px solid var(--rating-color) ;
        width: 98%;
        padding-bottom: 8px;
        margin-inline: auto;
    }
    
    .spaceBetween{
        margin-inline: 0px !important  ;
        margin-top: 20px;
    }

    .profileStyle{
        margin-top: 5px;
    }

    .picBorder {
        display: none;
        padding: 10px 0 0 10px;
        border-top: 5px solid var(--rating-color);
        border-left: 5px solid var(--rating-color);
    }

}


.coverStyle{
    background-color:rgba(26, 26, 27, 0.5) ;

}



  
.hrStyle {
    width: 100%;
    margin-inline: auto;
    border: 1px solid var(--rating-color) ;
    
}



.picAbout{
    width: 100%;
    
}

.coverStyle:hover{
    cursor: grab;
}

.coverStyle:active{
    cursor: grabbing;
}

.colorMore{
    color: var(--main-color) !important;
    font-size: 18px !important;
    transition: all 0.5s;
}

.colorMore:hover{
    color: var(--rating-color) !important;
    transform: scale(1.2);
}

